import { orderService, technicianService } from '@/services';
import { defineStore } from 'pinia';

export const useTechnicianStore = defineStore('technician', {
  state: () => ({
    todo: undefined,
    ratings: [],
  }),
  actions: {
    async getTechnicianTodo() {
      try {
        const response = await orderService.getOrderForTechnician();
        this.$state.todo = response.data;
      } catch (error) {
        throw error;
      }
    },

    async getTechnicianRatings(start = Date.now(), end = Date.now() + 10000) {
      try {
        const response = await technicianService.getTechniciansAndRatings(start, end);
        this.$state.ratings = response.data;
      } catch (error) {
        throw error;
      }
    },
  },
});
