import { _axios } from "@/plugins/axios";
import { ApiError } from "@/utils/error";
import { TokenService } from "./TokenService";

class AdminService {
  constructor() {
    this.axios = _axios;
    this.tokenService = new TokenService();
  }

  async create(payload, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .post('admins', payload, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async changePassword(userId, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`admins/${userId}/password`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async updatePersonalInfo(userId, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`admins/${userId}`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async updateAdminAddress(addressId, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`admins/addresses/${addressId}`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getAdminAddress(addressId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`admins/addresses/${addressId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getAllAdminAddresses(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`admins/addresses`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async get(userId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`admins/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async uploadProfilePhoto(userId, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`admins/${userId}/photos`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async removeProfilePhoto(userId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .delete(`admins/${userId}/photos`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getOrders(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`orders`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getLoginLogs(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`auth/logs`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getAdmins(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`admins`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }
}

export const adminService = new AdminService();
