import faker from './faker';
import helper from './helper';
import colors from './colors';

export default (app) => {
  app.use(faker);
  app.use(helper);
  app.use(colors);
};

export function stringToCents(amount) {
  // Convert to dollar amount from cents
  if (typeof amount !== 'string') throw new Error('Invalid argument type');
  let amountValue = Number(amount) * 100;
  if (typeof amount === 'string') {
    const [dollars, _cents] = amount.replace(/[^0-9.]/g, '').split('.');
    if (_cents !== undefined) {
      amountValue = Number(dollars) * 100;
      if (_cents.length > 1) amountValue += Number(_cents.slice(0, 2));
      else amountValue += Number(_cents) * 10;
    }
  }
  return amountValue;
}

export function normalizeCase(text) {
  let normalized = text[0];
  for (let k = 1; k < text.length; k++) {
    normalized = isUppercase(text[k]) ? `${normalized} ${text[k]}` : normalized + text[k];
  }
  return normalized;
}

/**
 * Check whether a character is in uppercase or not
 * @param {string} text
 * @returns boolean
 */
export function isUppercase(text) {
  if (text.length !== 1) {
    throw new Error('Invalid argument, text must be of length 1');
  }

  if (text.charCodeAt(0) >= 'A'.charCodeAt(0) && text.charCodeAt(0) <= 'Z'.charCodeAt(0)) {
    return true;
  }
  return false;
}

/**
 * Check whether a character is in lower case or not
 * @param {string} text
 * @returns boolean
 */
export function isLowercase(text) {
  return !isUppercase(text);
}
