import { chatService } from '@/services/ChatService';
import { defineStore } from 'pinia';

export const useChatStore = defineStore('chatStore', {
  state: () => ({
    activeChat: {
      from: '',
      messages: [],
    },
    chatRooms: [],
    customers: [],
    admins: [],
  }),
  getters: {},
  actions: {
    async getCustomers() {
      try {
        const response = await chatService.getCustomers();
        this.$state.customers = response.data;
      } catch (error) {
        console.log(error);
        this.$state.customers = [];
      }
    },

    async getAdmins() {
      try {
        const response = await chatService.getAdmins();
        this.$state.admins = response.data;
      } catch (error) {
        console.log(error);
        this.$state.admins = [];
      }
    },

    async getChatRooms() {
      try {
        const response = await chatService.getRooms();
        this.$state.chatRooms = response.data;
      } catch (error) {
        console.log(error);
        this.$state.chatRooms = [];
      }
    },
  },
});
