import './plugins/axios';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import globalComponents from './global-components';
import utils from './utils';
import Toast, { TYPE } from 'vue-toastification';
import './assets/css/app.css';
const toastOptions = {
  //   toastDefaults: {
  //     // ToastOptions object for each type of toast
  //     [TYPE.ERROR]: {
  //       timeout: 2000,
  //       closeButton: true,
  //     },
  //     [TYPE.SUCCESS]: {
  //       timeout: 2000,
  //       hideProgressBar: true,
  //     },
  //   },
};

// This should never throw an error
// If it does then handle properly

const app = createApp(App).use(Toast, toastOptions).use(router).use(createPinia());

globalComponents(app);
utils(app);

app.mount('#app');
