import { _axios } from '@/plugins/axios';
import { ApiError } from '@/utils/error';
import { TokenService } from './TokenService';

class CustomerService {
  constructor() {
    this.axios = _axios;
    this.tokenService = new TokenService();
  }
  async create(loginData) {
    try {
      return await this.axios.post('customers', loginData).then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async uploadResaleCertificate(userId, formData, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`customers/${userId}/resale`, formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async updateAddress(userId, data, token) {
    const { billingAddress, shippingAddress } = data;

    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `customers/${userId}/address`,
          {
            billingAddress,
            shippingAddress,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async get(userId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`customers/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getOrders(userId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`customers/${userId}/orders`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getCustomers(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`customers`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  /**
   * Gets stats regarding all customers
   * @param {string} token
   * @returns Stats object
   */
  async getCustomersStats(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`customers/stats`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }
}

export const customerService = new CustomerService();
