<template>
  <router-view />
</template>

<script setup>
import { onBeforeMount, onMounted, } from 'vue';
import { useAppStore } from '@/stores/app';

const appStore = useAppStore();


onBeforeMount(async () => {
  appStore.initAuth();
  if (!appStore.$state.user && appStore.$state.auth.authenticated) {
    await appStore.getUserData();
  }
})

onMounted(() => {
  console.log('App mounted');
})

</script>