import { defineStore } from 'pinia';
import { useAppStore } from './app';

export const useSideMenuStore = defineStore('sideMenu', {
  state: () => ({
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'side-menu-dashboard',
        title: 'Dashboard',
        pageName: 'dashboard',
      },
      {
        icon: 'LayoutListIcon',
        pageName: 'orders',
        title: 'Manage Orders',
      },
    ],
  }),
  getters: {
    getMenu: (state) => {
      const appStore = useAppStore();

      const organizationMenus = [
        {
          icon: 'Settings2Icon',
          pageName: 'admins',
          title: 'Admins',
        },
      ];

      const technicianMenus = [
        {
          icon: 'Settings2Icon',
          pageName: 'technician-todo',
          title: 'To Do',
        },
      ];

      const adminMenus = [
        {
          icon: 'UsersIcon',
          pageName: 'customers',
          title: 'Customers',
        },
        {
          icon: 'Settings2Icon',
          pageName: 'technicians',
          title: 'Technicians',
        },
        {
          icon: 'StarIcon',
          pageName: 'technician-ratings',
          title: 'Technician Ratings',
        },
        {
          icon: 'FilePlusIcon',
          pageName: 'export-orders',
          title: 'Export data',
        },
      ];

      const customerMenus = [
        {
          icon: 'FileIcon',
          pageName: 'invoices',
          title: 'Invoices',
        },
        // CHAT HAS BEEN DISABLED BASED ON FEEDBACK FROM OWNER
        // {
        //   icon: 'MessageSquareIcon',
        //   pageName: 'chat',
        //   title: 'Chat',
        // },
      ];

      let roleBasedMenus = [...state.menu];
      if (appStore.isTechnician) roleBasedMenus = [...state.menu, ...technicianMenus];
      if (appStore.isCustomer) roleBasedMenus = [...state.menu, ...customerMenus];
      if (appStore.isAdmin) roleBasedMenus = [...state.menu, ...customerMenus, ...adminMenus];
      if (appStore.isOrganization)
        roleBasedMenus = [...state.menu, ...customerMenus, ...adminMenus, ...organizationMenus];
      // console.log('Role', appStore.$state.auth.role,  {roleBasedMenus});
      return roleBasedMenus;
    },
  },
});
