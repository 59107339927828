export class TokenService {
  constructor(tokenName = 'auth_token') {
    this.token_name = tokenName;
  }

  get() {
    const token = localStorage.getItem(this.token_name);
    return token && token.trim().length ? token : undefined;
  }

  save(token) {
    localStorage.setItem(this.token_name, token);
  }
  remove() {
    localStorage.removeItem(this.token_name);
  }

  static _base64ToUtf8(str) {
    return decodeURIComponent(window.atob(str));
  }

  static getPayload(token) {
    try {
      const payload = JSON.parse(this._base64ToUtf8(token.split('.')[1]));
      return payload;
    } catch (error) {
      console.error('Get payload(): Failed to decode', error);
    }
  }

  static isValid(token) {
    if (token == undefined || token == null || token.length == 0) return false;
    try {
      const payload = JSON.parse(this._base64ToUtf8(token.split('.')[1]));
      const currentTime = Math.floor(new Date().valueOf() / 1000);
      const expiration = payload['exp'];
      return expiration - currentTime > 0;
    } catch (error) {
      console.error('isValid(): Failed to decode', error);
      return false;
    }
  }
}
