import SideMenu from '@/layouts/side-menu.vue';
import { useAppStore } from '@/stores/app';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/dashboard',
    component: SideMenu,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue'),
        meta: {
          requireAuth: true,
        },
      },

      {
        path: '/complete-profile',
        name: 'complete-profile',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "complete-profile" */ '@/views/Profile/CompleteProfile.vue'),
        children: [
          {
            path: '/complete-profile',
            name: 'complete-profile-1',
            alias: ['complete-profile-1', 'complete-profile-1'],
            meta: {
              requireAuth: true,
            },
            component: () =>
              import(/* webpackChunkName: "complete-profile" */ '@/views/Profile/CompleteProfileStep1.vue'),
          },
          {
            path: '/complete-profile-step-2',
            name: 'complete-profile-2',
            meta: {
              requireAuth: true,
            },
            component: () =>
              import(/* webpackChunkName: "complete-profile" */ '@/views/Profile/CompleteProfileStep2.vue'),
          },
          {
            path: '/complete-profile-step-3',
            name: 'complete-profile-3',
            meta: {
              requireAuth: true,
            },
            component: () =>
              import(/* webpackChunkName: "complete-profile" */ '@/views/Profile/CompleteProfileStep3.vue'),
          },
          {
            path: '/complete-profile-done',
            name: 'complete-profile-done',
            meta: {
              requireAuth: true,
            },
            component: () =>
              import(/* webpackChunkName: "complete-profile" */ '@/views/Profile/CompleteProfileDone.vue'),
          },
        ],
      },

      {
        path: 'invoices',
        name: 'invoices',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "invoice" */ '@/views/Invoice/InvoiceManager.vue'),
      },
      {
        path: 'invoices/:id',
        name: 'invoice',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "invoice" */ '@/views/Invoice/Invoice.vue'),
      },
      {
        path: 'customers',
        name: 'customers',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "customer" */ '@/views/Customer/Customers.vue'),
      },
      {
        path: 'customers/:id',
        name: 'customer',
        meta: {
          requireAuth: true,
        },
        props: (route) => ({ id: route.params.id }),
        component: () => import(/* webpackChunkName: "customer" */ '@/views/Customer/Customer.vue'),
      },
      {
        path: 'technicians',
        name: 'technicians',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "technicians" */ '@/views/Technician/Technicians.vue'),
      },
      {
        path: 'technician-ratings',
        name: 'technician-ratings',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "technicians" */ '@/views/Technician/TechnicianRatings.vue'),
      },
      {
        path: 'technician-todo',
        name: 'technician-todo',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "technicians" */ '@/views/Technician/TechnicianTodo.vue'),
      },
      {
        path: 'new-technician',
        name: 'new-technician',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "technicians" */ '@/views/Technician/NewTechnician.vue'),
      },
      {
        path: 'admins',
        name: 'admins',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "admins" */ '@/views/Admin/Admins.vue'),
      },
      {
        path: 'new-admin',
        name: 'new-admin',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "admins" */ '@/views/Admin/NewAdmin.vue'),
      },
      // CHAT HAS BEEN DISABLED BASED ON FEEDBACK FROM OWNERS
      // {
      //   path: 'chat',
      //   name: 'chat',
      //   meta: {
      //     requireAuth: true,
      //   },
      //   component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat.vue'),
      // },
      {
        path: 'orders',
        name: 'orders',
        meta: {
          requireAuth: true,
        },
        props: (route) => ({ limit: route.query.limit, page: route.query.page  }),
        component: () => import(/* webpackChunkName: "order" */ '@/views/Order/OrderManager.vue'),
      },
      {
        path: 'export-orders',
        name: 'export-orders',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "order" */ '@/views/Order/OrderExportManager.vue'),
      },
      {
        path: 'orders/:ref',
        name: 'order',
        meta: {
          requireAuth: true,
        },
        props: (route) => ({ reference: route.params.ref }),
        component: () => import(/* webpackChunkName: "order" */ '@/views/Order/OrderDetails.vue'),
      },
      {
        path: 'new-order',
        name: 'new-order',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Order/NewOrder.vue'),
      },
      {
        path: 'help',
        name: 'help',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "faq" */ '@/views/Help.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        meta: {
          requireAuth: true,
        },
        component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/Profile.vue'),
        children: [
          {
            path: '',
            name: 'profile-overview',
            alias: ['overview'],
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/ProfileOverview.vue'),
          },
          {
            path: 'edit-profile',
            name: 'edit-profile',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/EditProfile.vue'),
          },
          {
            path: 'activity-logs',
            name: 'activity-logs',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "admin-tools" */ '@/views/Admin/ActivityLogs.vue'),
          },
          {
            path: 'change-password',
            name: 'change-password',
            meta: {
              requireAuth: true,
            },
            component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/ChangePassword.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    // component: LoginView,
    props: {
      loginFor: 'customer',
    },
  },
  {
    path: '/login/admin',
    name: 'admin-login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    props: {
      loginFor: 'admin',
    },
  },
  {
    path: '/login/technician',
    name: 'technician-login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    props: {
      loginFor: 'technician',
    },
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Account/Register.vue'),
  },

  {
    path: '/activate',
    name: 'activate',
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Account/ActivateAccount.vue'),
  },

  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "passwords" */ '@/views/Account/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "passwords" */ '@/views/Account/ResetPassword.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "legal" */ '@/views/Legal/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import(/* webpackChunkName: "legal" */ '@/views/Legal/TermsOfService.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    meta: {
      title: 'Page not found',
    },
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const appStore = useAppStore();
  if (to.meta.requireAuth && appStore.isAuthenticated) {
    next();
  } else if (to.meta.requireAuth && !appStore.isAuthenticated) {
    if (to.name === 'admin-login' || to.name === 'technician') {
      next();
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
  }
});

export default router;
