import { orderService } from '@/services';
import { defineStore } from 'pinia';

export const useOrdersStore = defineStore('orders-store', {
  state() {
    return {
      stats: {
        receivedOrders: 0,
        acceptedOrders: 0,
        totalOrders: 0,
        createdOrders: 0,
        shippedOrders: 0,
        repairedOrders: 0,
        inProgressOrders: 0,
        completedOrders: 0,
      },
      filteredOrders: [],
      pagination: {
        totalPages: 1,
        pageSize: 0,
        pageNumber: 1,
        total: 0,
      },
    };
  },
  actions: {
    async getStats() {
      const response = await orderService.getOrderStats();
      this.stats = response.data;
    },

    async filterOrders(query) {
      try {
        const response = await orderService.filter(query);
        this.filteredOrders = response.data.items;
        delete response.data.items;
        this.pagination = response.data;
      } catch (error) {
        throw error;
      }
    },

    async filterOrdersForExport(by, filterValue) {
      try {
        const response = await orderService.filterToExport(by, filterValue);
        this.filteredOrders = response.data;
      } catch (error) {
        throw error;
      }
    },
  },
});
