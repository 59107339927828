import { _axios } from '@/plugins/axios';
import { ApiError } from '@/utils/error';

export class AuthService {
  constructor() {
    this.axios = _axios;
  }
  async login(loginData) {
    try {
      return await this.axios
        .post('auth/login', {
          email: loginData.email,
          password: loginData.password,
          rememberMe: false,
          role: 'customer',
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async loginAdmin(loginData) {
    try {
      return await this.axios
        .post('auth/login', {
          email: loginData.email,
          password: loginData.password,
          rememberMe: false,
          role: 'admin',
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async loginTechnician(loginData) {
    try {
      return await this.axios
        .post('auth/login', {
          email: loginData.email,
          password: loginData.password,
          rememberMe: false,
          role: 'technician',
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async verifyEmail(token) {
    try {
      return await this.axios
        .post('auth/verify', {
          type: 'Bearer',
          value: token,
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async sendResetPasswordMail(email) {
    try {
      return await this.axios
        .post('auth/reset-password', {
          email,
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async resetPassword(token, password, confirmPassword) {
    try {
      return await this.axios
        .put('auth/reset-password', {
          password,
          confirmPassword,
          token: {
            type: 'Bearer',
            value: token,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  static checkPasswordStrength(password) {
    if (!password || typeof password !== 'string') return 0;
    let score = 0;
    if (password.length < 8) {
      return score;
    }

    score += 10;
    if (password.length > 16) score += 10;
    if (password.match(/[0-9]/)) score += 20;
    if (password.match(/[a-z]/)) score += 20;
    if (password.match(/[A-Z]/)) score += 20;
    if (password.match(/[#!@~)(*&^%$)-_"|;:><.,}{`}]/)) score += 20;
    return score;
  }
}

export const authService = new AuthService();
