export class ApiError {
  constructor(message, data, status, errors = {}) {
    this.status = status;
    this.data = data;
    this.message = message;
    this.errors = errors;
  }

  static toError(error) {
    if (error.response) {
      const response = error.response;
      const apiError = new ApiError(
        response.data.message || response.data.title || response.statusText || error.message,
        response.data,
        response.status || response.data.statusCode,
        response.data.errors
      );

      if (apiError.status == 401) apiError.message = 'Session expired, please login again';
      return apiError;
    }
    return error;
  }
}
