import { _axios } from '@/plugins/axios';
import { TokenService } from './TokenService';
import { ApiError } from '@/utils/error';

export class InvoiceService {
  constructor() {
    this.axios = _axios;
    this.tokenService = new TokenService();
  }

  async getInvoices(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`invoices`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async createInvoice(data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .post(`invoices`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async markAsPaid(invoiceRef, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `invoices/${invoiceRef}/status`,
          {
            paid: true,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getInvoicesByName(username, token) {
    if (!username) {
      throw new Error('Username is required');
    }
  
    const authToken = token || this.tokenService.get();
  
    try {
      return await this.axios
        .get(`invoices/q`, {
          params: { username },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async filter(query, token) {
    const authToken = token || this.tokenService.get();
    let queryStringGenerator = [];

    Object.keys(query).forEach((key, index)=>{
      queryStringGenerator.push(`${key}=${query[key]}`);
    })

    try {
      return await this.axios
        .get(`invoices?${queryStringGenerator.join('&')}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);

    } catch (error) {
      throw ApiError.toError(error);
    }
  }
}

export const invoiceService = new InvoiceService();
