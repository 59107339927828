import { _axios } from '@/plugins/axios';
import { ApiError } from '@/utils/error';
import { TokenService } from './TokenService';

class TechnicianService {
  constructor() {
    this.axios = _axios;
    this.tokenService = new TokenService();
  }
  async create(payload, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .post('technicians', payload, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async changePassword(userId, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`technicians/${userId}/password`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async updatePersonalInfo(userId, data, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(`technicians/${userId}`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async updateAddress(userId, data, token) {
    const { billingAddress, shippingAddress } = data;

    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .put(
          `technicians/${userId}/address`,
          {
            billingAddress,
            shippingAddress,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async get(userId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`technicians/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getTechnicians(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`technicians`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getTechniciansAndRatings(startDate, endDate, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`technicians/ratings?StartDate=${startDate}&EndDate=${endDate}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }
}

export const technicianService = new TechnicianService();
