import 'zoom-vanilla.js/dist/zoom-vanilla.min.js';
import Chart from './chart/Main.vue';
import Litepicker from './litepicker/Main.vue';
import Tippy from './tippy/Main.vue';
import TippyContent from './tippy-content/Main.vue';
import LoadingIcon from './loading-icon/Main.vue';
import TinySlider from './tiny-slider/Main.vue';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './modal';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownContent,
  DropdownItem,
  DropdownHeader,
  DropdownFooter,
  DropdownDivider,
} from './dropdown';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from './tab';
import { Alert } from './alert';

import LucideIcons from './lucide';

export default (app) => {
  app.component('Chart', Chart);
  app.component('Litepicker', Litepicker);
  app.component('Tippy', Tippy);
  app.component('TippyContent', TippyContent);
  app.component('LoadingIcon', LoadingIcon);  
  app.component('TinySlider', TinySlider);
  app.component('Modal', Modal);
  app.component('ModalHeader', ModalHeader);
  app.component('ModalBody', ModalBody);
  app.component('ModalFooter', ModalFooter);
  app.component('Dropdown', Dropdown);
  app.component('DropdownToggle', DropdownToggle);
  app.component('DropdownMenu', DropdownMenu);
  app.component('DropdownContent', DropdownContent);
  app.component('DropdownItem', DropdownItem);
  app.component('DropdownHeader', DropdownHeader);
  app.component('DropdownFooter', DropdownFooter);
  app.component('DropdownDivider', DropdownDivider);
  app.component('TabGroup', TabGroup);
  app.component('TabList', TabList);
  app.component('Tab', Tab);
  app.component('TabPanels', TabPanels);
  app.component('TabPanel', TabPanel);
  app.component('Alert', Alert);

  for (const [key, icon] of Object.entries(LucideIcons)) {
    app.component(key, icon);
  }
};
