import { defineStore } from 'pinia';
import { invoiceService } from '@/services';

export const useInvoicesStore = defineStore('invoices-store', {
  state() {
    return {
      filteredInvoices: [],
      pagination: {
        totalPages: 1,
        pageSize: 0,
        pageNumber: 1,
        total: 0,
      },
    };
  },
  actions: 
  {
    async getInvoices() {
      try {
        const response = await invoiceService.getInvoices();
        this.filteredInvoices = response.data.items;
        delete response.data.items;
        this.pagination = response.data;
      } catch (error) {
        throw error;
      }
      },
    
    async filterInvoices(query) {
      try {
        const response = await invoiceService.filter(query);
        this.filteredInvoices = response.data.items;
        delete response.data.items;
        this.pagination = response.data;
      } catch (error) {
        throw error;
      }
      },
  },
});
