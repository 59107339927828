import { _axios } from '@/plugins/axios';
import { ApiError } from '@/utils/error';
import { TokenService } from './TokenService';

class ChatService {
  constructor() {
    this.axios = _axios;
    this.tokenService = new TokenService();
  }

  async getRooms(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`rooms`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getMessagesByRoomId(roomId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`rooms/${roomId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getCustomers(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`chats/customers`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getAdmins(token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`chats/admins`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async UpdateMessage(data, token) {
    const authToken = token || this.tokenService.get();
    const payload = {
      receiverId: '',
      content: '',
      roomId: '',
      file: '',
    };
    try {
      return await this.axios
        .put(`messages/${data.id}`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  /**
   * Create new message
   * @param {*} data
   * @param {*} token
   * @returns
   */
  async createMessage(data, token) {
    const authToken = token || this.tokenService.get();

    const payload = new FormData();
    payload.append('content', data.content);

    if (data.roomId.length) payload.append('roomId', data.roomId);
    payload.append('receiverId', data.receiverId);
    payload.append('file', data.file);
    try {
      return await this.axios
        .post(`messages`, payload, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getMessageById(messageId, token) {
    const authToken = token || this.tokenService.get();
    try {
      return await this.axios
        .get(`messages/${messageId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }
}

export const chatService = new ChatService();
