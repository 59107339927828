import { _axios } from '@/plugins/axios';
import { ApiError } from '@/utils/error';
import { TokenService } from './TokenService';

class ValuesService {
  constructor() {
    this.axios = _axios;
    this.tokenService = new TokenService();
  }
  async getWatchColors() {
    try {
      return await this.axios.get('watch-colors').then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }
  async getWatchBrands() {
    try {
      return await this.axios.get('watch-brands').then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getServiceTypes() {
    try {
      return await this.axios.get('service-types').then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }

  async getItemNames() {
    try {
      return await this.axios.get('item-names').then((res) => res.data);
    } catch (error) {
      throw ApiError.toError(error);
    }
  }
}

export const valuesService = new ValuesService();
