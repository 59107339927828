<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <nav aria-label="breadcrumb" class="-intro-x mr-auto hidden sm:flex">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">{{ appStore.appName }}</a>
        </li>
        <li class="breadcrumb-item active capitalize" aria-current="page">
          {{ pageName }}
        </li>
      </ol>
    </nav>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <!-- <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-slate-500" />
      </div>
      <a class="notification sm:hidden" href>
        <SearchIcon class="notification__icon dark:text-slate-500" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href class="flex items-center">
              <div
                class="w-8 h-8 bg-success/20 dark:bg-success/10 text-success flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href class="flex items-center mt-2">
              <div class="w-8 h-8 bg-pending/10 text-pending flex items-center justify-center rounded-full">
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-primary/10 dark:bg-primary/20 text-primary/80 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a v-for="(faker, fakerKey) in take($f(), 4)" :key="fakerKey" href class="flex items-center mt-2">
              <div class="w-8 h-8 image-fit">
                <img alt="Horologica Admin Dashboard" class="rounded-full" :src="faker.photos[0]" />
              </div>
              <div class="ml-3">{{ faker.users[0].name }}</div>
              <div class="ml-auto w-48 truncate text-slate-500 text-xs text-right">{{ faker.users[0].email }}</div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a v-for="(faker, fakerKey) in take($f(), 4)" :key="fakerKey" href class="flex items-center mt-2">
            <div class="w-8 h-8 image-fit">
              <img alt="Horologica Admin Dashboard" class="rounded-full" :src="faker.images[0]" />
            </div>
            <div class="ml-3">{{ faker.products[0].name }}</div>
            <div class="ml-auto w-48 truncate text-slate-500 text-xs text-right">{{ faker.products[0].category }}</div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <!-- <Dropdown class="intro-x mr-auto sm:mr-6">
      <DropdownToggle tag="div" role="button" class="notification notification--bullet cursor-pointer">
        <BellIcon class="notification__icon dark:text-slate-500" />
      </DropdownToggle>
      <DropdownMenu class="notification-content pt-2">
        <DropdownContent tag="div" class="notification-content__box">
          <div class="notification-content__title">Notifications</div>
          <div
            v-for="(faker, fakerKey) in take($f(), 1)"
            :key="fakerKey"
            class="cursor-pointer relative flex items-center"
            :class="{ 'mt-5': fakerKey }"
          >
            <div class="w-12 h-12 flex-none image-fit mr-1">
              <img
                alt="Horologica Admin Dashboard"
                class="rounded-full"
                :src="require('@/assets/images/generic_user.png')"
              />
              <div
                class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white dark:border-darkmode-600"
              ></div>
            </div>
            <div class="ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="font-medium truncate mr-5">
                  Account Created
                </a>
                <div class="text-xs text-slate-400 ml-auto whitespace-nowrap">{{ faker.times[0] }}</div>
              </div>
              <div class="w-full truncate text-slate-500 mt-0.5">Please complete your profile to continue</div>
            </div>
          </div>
        </DropdownContent>
      </DropdownMenu>
    </Dropdown> -->
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <Dropdown class="intro-x w-8 h-8">
      <DropdownToggle
        tag="div"
        role="button"
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      >
        <img
          ref="accountDropDown"
          alt="Horologica Admin Dashboard"
          :src="
            user?.profilePhoto || require('@/assets/images/generic_user.png')
          "
        />
      </DropdownToggle>

      <DropdownMenu class="w-56">
        <DropdownContent class="bg-primary text-white">
          <DropdownHeader tag="div" class="!font-normal">
            <div v-if="user" class="font-medium">
              {{ `${user.firstName} ${user.lastName}` }}
            </div>
            <div
              v-if="user"
              class="text-xs text-white/70 mt-0.5 dark:text-slate-500"
            >
              {{ user.email }}
            </div>
          </DropdownHeader>
          <DropdownDivider class="border-white/[0.08]" />
          <DropdownItem
            @click="handleDropdownClick('profile-overview')"
            class="dropdown-item hover:bg-white/5"
          >
            <UserIcon class="w-4 h-4 mr-2" />Profile
          </DropdownItem>
          <DropdownItem
            @click="handleDropdownClick('edit-profile')"
            class="dropdown-item hover:bg-white/5"
          >
            <EditIcon class="w-4 h-4 mr-2" />Edit Account
          </DropdownItem>
          <DropdownItem
            @click="handleDropdownClick('change-password')"
            class="dropdown-item hover:bg-white/5"
          >
            <LockIcon class="w-4 h-4 mr-2" />Reset Password
          </DropdownItem>

          <DropdownItem
            v-if="appStore.isAdminOrOrganization"
            @click="handleDropdownClick('activity-logs')"
            class="dropdown-item hover:bg-white/5"
          >
            <ActivityIcon class="w-4 h-4 mr-2" />Activity Logs
          </DropdownItem>

          <DropdownItem
            @click="handleDropdownClick('help')"
            class="dropdown-item hover:bg-white/5"
          >
            <HelpCircleIcon class="w-4 h-4 mr-2" />Help
          </DropdownItem>
          <DropdownDivider class="border-white/[0.08]" />
          <DropdownItem
            @click="handleLogout"
            class="dropdown-item hover:bg-white/5"
          >
            <ToggleRightIcon class="w-4 h-4 mr-2" />Logout
          </DropdownItem>
        </DropdownContent>
      </DropdownMenu>
    </Dropdown>

    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script setup>
import { ref, computed } from 'vue';
import { useAppStore } from '@/stores/app';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

const appStore = useAppStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

const user = computed(() => appStore.user);

const pageName = computed(() => route.name.toLowerCase());

const searchDropdown = ref(false);

const accountDropDown = ref(null);

const showSearchDropdown = () => {
  searchDropdown.value = true;
};
const hideSearchDropdown = () => {
  searchDropdown.value = false;
};

const handleDropdownClick = async (navigtateTo) => {
  accountDropDown.value.click();
  await router.push({ name: navigtateTo });
};

const handleLogout = async () => {
  accountDropDown.value.click();
  if (appStore.isAdminOrOrganization) {
    await router.push({ name: 'admin-login' });
  } else if (appStore.isTechnician) {
    await router.push({ name: 'technician-login' });
  } else {
    await router.push({ name: 'login' });
  }

  appStore.logoutUser();
  toast.success('Logged out!');
};
</script>
