import { TokenService, adminService, customerService, orderService, technicianService } from '@/services';
import { defineStore } from 'pinia';
const _tokenService = new TokenService();

export const useAppStore = defineStore('app-store', {
  state: () => ({
    appName: process.env.VUE_APP_NAME || 'Horologica',
    appLoading: false,
    message: '',
    auth: {
      token: '',
      authenticated: false,
      role: '',
      tokenPayload: undefined,
    },
    user: undefined,
    customer: undefined,
    orders: [],
    pageNumber: undefined,
    totalPages: undefined,
    technicians: [],
    admins: [],
  }),
  getters: {
    isCustomer: (state) => state.auth.role === 'customer',
    isAdmin: (state) => state.auth.role === 'admin',
    isTechnician: (state) => state.auth.role === 'technician',
    isOrganization: (state) => state.auth.role === 'organization',
    isAdminOrOrganization: (state) => state.auth.role === 'admin' || state.auth.role === 'organization',
    isAuthenticated: (state) => state.auth.authenticated,
  },
  actions: {
    initAuth() {
      const token = _tokenService.get();
      try {
        let isValid = TokenService.isValid(token);
        if (isValid) {
          this.loginUser(token);
        } else {
          this.logoutUser();
        }
      } catch (error) {
        this.logoutUser();
      }
    },

    loginUser(token) {
      this.$state.auth.token = token;
      this.$state.auth.authenticated = true;
      this.$state.auth.tokenPayload = TokenService.getPayload(token);
      this.$state.auth.role = this.$state.auth.tokenPayload['role'];
      _tokenService.save(token);
    },

    async getUserData() {
      this.$state.appLoading = true;
      try {
        let response = undefined;
        if (this.$state.auth.role == 'admin') {
          response = await adminService.get(this.$state.auth.tokenPayload['sub']);
        } else if (this.$state.auth.role == 'customer') {
          response = await customerService.get(this.$state.auth.tokenPayload['sub']);
        } else if (this.$state.auth.role == 'technician') {
          response = await technicianService.get(this.$state.auth.tokenPayload['sub']);
        } else if (this.$state.auth.role == 'organization') {
          response = await technicianService.get(this.$state.auth.tokenPayload['sub']);
        } else {
          throw new Error('Unsupported role');
        }
        this.$state.user = response.data;
      } catch (error) {
        throw error;
      }
      this.appLoading = false;
    },

    async getOrders(offset, limit) {
      try {
        const response = await orderService.getOrders(offset, limit);
        const orders = response.data.items;
        this.$state.orders = orders;
        this.$state.pageNumber = response.data.pageNumber;
        this.$state.totalPages = response.data.totalPages;
      } catch (error) {
        throw error;
      }
    },

    async getTechnicians() {
      try {
        const response = await technicianService.getTechnicians();
        this.$state.technicians = response.data;
      } catch (error) {
        throw error;
      }
    },

    async getAdmins() {
      try {
        const response = await adminService.getAdmins();
        this.$state.admins = response.data;
      } catch (error) {
        throw error;
      }
    },

    async cancelOrder(orderRef) {
      try {
        const response = await orderService.cancel(orderRef);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async receiveWatch(orderRef) {
      try {
        const response = await orderService.receiveWatch(orderRef);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async acceptOrder(orderRef) {
      try {
        const response = await orderService.accept(orderRef);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async shipOrder(orderRef) {
      try {
        const response = await orderService.ship(orderRef);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async assignOrder(orderRef, data) {
      try {
        const response = await orderService.assignOrder(orderRef, data);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async completeOrder(orderRef) {
      try {
        const response = await orderService.complete(orderRef);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async rejectOrder(orderRef) {
      try {
        const response = await orderService.reject(orderRef);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async reopenOrder(orderRef) {
      try {
        const response = await orderService.reopen(orderRef);
        return response;
      } catch (error) {
        throw error;
      }
    },

    logoutUser() {
      // During logout reset the store to initial state
      this.$reset();
      _tokenService.remove();
    },
  },
});
